<template>
	<div class="view view-login" :class="app.day_moment">
		<home-background :day_moment="app.day_moment" />
		<div class="bg"></div>
		<div class="content">
			<div class="wrap">
				<div class="window" key="window">
					<transition name="slide" mode="out-in">

						<ValidationObserver
							ref="signUp"
							v-slot="{ handleSubmit }"
							tag="div"
							:key="step_id"
							class="validation-observer"
						>
							<FormsSignUp
								v-if="isFormElements"
								:context="getContext"
								class="form"
							/>

							<DisplaySignUp
								v-if="isContentTexts"
								:context="getContext"
								class="form"
							/>

						</ValidationObserver>
					</transition>
				</div>

			</div>
		</div>

		<div class="hospital"></div>
	</div>
</template>

<script>
	import Apollo from '@/apollo/apollo-client'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { mapState, mapGetters } from 'vuex'
	import { LANGUAGE } from '@/js/factory/language'
	import { getValidation } from '@/js/utils/validations'
	import FormsSignUp from '@/components/form/forms-signup'
	import DisplaySignUp from '@/components/form/display-signup'
	import { removeAccessToken } from '@/js/jwt/jwt'
	import HomeBackground from '@/components/home-background'

	export default {
		name: 'view-signup',
		data() {
			const SIGNUP = 'signup'
			return {
				SIGNUP,
				loading: false,
				message: '',
				form: '',
				avatar: 0,
				practiceName: null,
				currentLocale: null,
				invalidCredentials: false,
				layout: null,
			}
		},
		components: {
			DisplaySignUp,
			FormsSignUp,
			ValidationProvider,
			ValidationObserver,
			HomeBackground
		},
		computed: {
			...mapState({
				loggedIn: state => state.user.loggedIn,
				subjectsToH: state => state.schema.data && state.schema.data.subjectsToH
					? state.schema.data.subjectsToH
					: null,
				schemaId: state => state.schema.data
					? state.schema.data.id
					: null,
				manageUser: state => state.user.manageUser,
				manageForms: state => state.user.manageForms,
				isFormElements: state => {
					const { user: { manageForms: { step: { formElements = null } = {} } = {} } = {} } = state
					return !!formElements
				},
				isContentTexts: state => {
					const { user: { manageForms: { step: { contentTexts = null } = {} } = {} } = {} } = state
					return !!contentTexts
				},
				getContext: state => state.user.logged_user || {}
			}),
			...mapGetters(['app', 'user']),
			step_id() {
				if (this.manageForms && this.manageForms.step && this.manageForms.step.id)
					return this.manageForms.step.id
				return null
			},
			language() {
				return LANGUAGE.getData()
			},
		},
		methods: {
			getValidation,
			close() {
				removeAccessToken()
				this.$store.dispatch('clearSignUpForm')
				this.$router.push({ name: 'login' })
			},
			async apolloQueries() {
				const response = await Apollo.query('CURRENT_CLIENT')
				if (response) {
					const { id } = response
					if (id) {
						await this.$store.dispatch('clientSet', {
							value: {
								id
							}
						})
					}
				}
				const value = await Apollo.query('SCHEMA', { id: process.env.VUE_APP_APOLLO_SCHEMA_ID })
				if (value) {
					await this.$store.dispatch('schemaSet', { value })
				}
			},
			hide_signup_message() {
				this.$store.commit('EDIT_USER_STATE', { signup_success: false })
			},
			set_avatar(n) {
				if (this.avatar === n) {
					this.avatar = 0
				} else {
					this.avatar = n
				}
			},
		},
		async mounted() {
			await this.apolloQueries()
			await this.$store.dispatch('set_main_loader', { main_loader: false })
			this.currentLocale = this.$i18n.locale()
			const data = this.currentLocale
			LANGUAGE.setData({ data })
			const { manageForms: { func: { start } = {} } = {} } = this
			if (start) {
				const { step } = await start() || {}
				if (!step) await this.$router.push({ name: 'home' })
				await this.$store.dispatch('setFormStep', { step })
			}
			setTimeout(() => {
				this.$emit('loaded')
			}, 250)
		}
	}
</script>

<style lang="stylus" scoped>
	@import '../assets/css/variables'


	#app.mobile
		.view-login
			.signup-message
				width calc(100% - 32px)



	.slide-enter-active, .slide-leave-active
		transition 0.25s easeOutQuart

	.slide-enter
		transform translateX(50px)
		opacity 0

	.slide-leave-to
		transform translateX(-50px)
		opacity 0


	.view-login
		background #E5FBFF bottom center no-repeat
		background-size cover
		height 100%
		transition 0.25s easeOutQuart
		transition-delay 0.25s

		&.night
			background-image url(../assets/img/home/sky-night.svg)
			.bg
				background-image url(../assets/img/login/clinic-bg-night.svg)
			.hospital
				background-image url(../assets/img/login/clinic-front-night.svg)

		.bg
			absolute 0
			background url(../assets/img/login/clinic-bg-day.svg) bottom center no-repeat
			background-size cover

		>>> .home-background
			.img.clinic
				display none

		.hospital
			position absolute
			left 43%
			bottom 6.2%
			width 700px
			height calc(95% - 64px)
			background url(../assets/img/login/clinic-front-day.svg) bottom center no-repeat
			background-size contain

		.content
			position absolute
			left 0
			right 0
			top calc(50% - 250px)
			width 100%
			height 450px
			background-color alpha(#0A172B, 95%)

			.wrap
				position absolute
				right 60%
				// margin 0 100px 0 0
				width 410px
				height 100%
				flex center center
				flex-direction column

			.logo
				margin 0 0 16px 0
				height 64px
				line-height 64px
				white-space nowrap
				color #fff
				text-transform uppercase
				text-align center
				font-weight 700
				font-size 3rem
				text-shadow -1px -1px alpha(#000, 50%)

			.main-buttons
				width 100%

				&.slide-enter
					transform translateX(50px)

				.button
					flex center center
					width 100%
					height 64px
					margin 24px 0
					background red
					background-size 100% 100%
					border-radius 8px
					box-shadow 0 4px 0 0 alpha(#000, 50%), 0 4px 0 8px alpha(#000, 15%)
					color #fff
					text-transform uppercase
					font-weight 700
					text-shadow -1px -1px alpha(#000, 25%)
					font-size 1.6rem
					cursor pointer
					user-select none

					&:before
						content ''
						absolute 4px
						box-shadow inset 0 0 0 2px alpha(#fff, 15%)
						border-radius 8px

					&:after
						content ''
						absolute 0
						box-shadow inset 0 0 0 4px alpha(#000, 10%)
						border-radius 8px

					&.signup
						background-color yellow

					&:active
						transform translateY(4px)
						box-shadow 0 0px 0 8px alpha(#000, 15%)

			.window
				width 100%
				height 100%
				text-align left

				&.slide-leave-to
					transform translateX(-50px)

				.validation-observer
					height 100%

				.form
					display block
					height 100%
					padding 32px 0

					>>> form
						height 100%

						&.form-enter-active, &.form-leave-active
							transition 0.25s easeOutQuart
						&.form-enter
							transform translateX(100%)
							opacity 0
						&.form-leave-to
							transform translateX(-100%)
							opacity 0

						.line
							margin 0 0 12px 0 !important

						.label
							text-align left
							color white
							display block
							margin 0 0 4px 0px
							padding 0 0 0 16px

						.input
							display block
							width 100%
							padding 0 16px
							height 40px
							appearance none
							color dark
							border none
							background-color #fff
							border-radius 40px
							font inherit
							font-size 1.4rem
							color inherit
							outline none

							&:focus
								box-shadow 0 0 0 2px red

						select.input
							background #fff url(../assets/img/chevron-down.svg) right 12px center no-repeat
							background-size 16px 16px

						.error
							z-index 10000
							position absolute
							background-color white
							display block
							min-height 24px
							flex flex-start center
							color #f44336
							font-weight 700
							font-size 1.2rem
							line-height unset
							border black solid 1px
							padding 8px 12px
							user-select none
							box-shadow 0 0 16px 2px alpha(#000, 50%)
							border-radius 8px
							&.checkbox
								left 30px
								top 0
							&.text-input,
							&.password-input
								left 16px
								top 70px
							&.select-input
								left 16px
								top 70px

						.images
							flex flex-start flex-start
							flex space-between flex-start
							flex-wrap wrap
							padding 8px 0 0 0

							.image
								width (220px / 3 - 12px)
								height (220px / 3 - 12px)
								margin 0 0 16px 0
								background-color #ddd
								border-radius 50%
								cursor pointer
								transition transform 0.25s easeOutQuart
								// overflow hidden

								&.selected
									box-shadow 0 0 0 4px red
									transform scale(1.1)

								img
									width 100%
									height 100%
									border-radius 50%

					.title
						color white
						margin 0 0 32px 0
						padding 0 0 8px 0
						border-bottom 2px solid #fff
						text-align left
						font-size 2rem
						text-transform uppercase
						font-weight 700

					button[type="submit"]
						position fixed
						left 2000px
						top 2000px
						opacity 0

					>>> .line
						margin 0 0 16px 0

						&:last-child
							margin 0


					>>> .buttons
						position absolute
						right 0
						bottom 0
						margin 0
						.ui-button
							margin 0 0 0 16px
							font-weight 700

					>>> .ql-align-center
						text-align left
				.close
					position absolute
					left 8px
					top 8px
					width 40px
					height 40px
					background url(../assets/img/arrow-left.svg) center center no-repeat
					background-size 32px auto
					cursor pointer

		.signup-message
			position absolute
			right 16px
			top 16px
			max-width 350px
			padding 24px 24px 24px 76px
			background #8BC34A url(../assets/img/mail-white.svg) left 16px center no-repeat
			background-size 44px auto
			font-size 1.4rem
			line-height 18px
			color #fff
			cursor pointer
			&.signup-message-enter-active, &.signup-message-leave-active
				transition 0.25s easeOutQuart
			&.signup-message-enter, &.signup-message-leave-to
				transform translateX(100%)
				opacity 0
			p
				margin 0 0 8px 0
				&:last-child
					margin 0

		.change-locale
			position absolute
			left 16px
			top 16px
			width 64px
			height 64px
			background-color dark
			border-radius 50%
			font-size 1.4rem
			&:before
				content ''
				position absolute
				top calc(50% - 4px)
				right 12px
				width 0
				height 0
				border-style solid
				border-width 8px 6px 0 6px
				border-color #fff transparent transparent transparent
			select
				position absolute
				top 0
				bottom 0
				left 50%
				padding 0 20px 0 4px
				transform translateX(-50%)
				height 100%
				background none
				border none
				outline none
				appearance none
				font inherit
				color #fff
				text-align center
				flex center center
				font-weight 700
				text-transform uppercase
				option
					text-align center
					color dark
					padding 8px 0
					text-transform uppercase
	.errorCustom
		min-height 24px
		display flex
		-webkit-box-align center
		align-items center
		-webkit-box-pack start
		justify-content flex-start
		margin 4px 0 0 0
		padding-left 32px
		background url(../assets/img/login/alert-triangle.svg) left top no-repeat
		background-size 24px 24px
		color #f44336
		font-weight 700
		font-size 1.1rem
		line-height 12px



@media (max-width: 1200px)
	.view-login
		.hospital
			display block
			left 50%
			transform translateX(-50%)
			width 95%
			z-index 1
			height 100%
			max-height 90%
		.content
			z-index 2
			.wrap
				right auto
				left 50%
				transform translateX(-50%)
				margin 0
				width 100%
				max-width (410px + 32px)
				padding 0 32px

</style>
