<template>
	<ValidationObserver
		ref="signUp"
		v-slot="{ handleSubmit }"
		tag="div"
	>
    <form
			class="form"
			method="post"
			ref="form"
			@submit.prevent="handleSubmit(submit)"
			@keyup.enter='handleSubmit(submit)'
		>
			<div
				v-for="group in formElementsGroups"
				:class="css(group)"
			>
				<div
					v-for="entry in getFormElement(group)"
				>
					<component
						:ref="getElementInputId(entry) ? `input-${getElementInputId(entry)}` : ''"
						:is="getElementType(entry)"
						:data="entry"
						@tap="tap"
					/>
					<component
						:is="getElementType(entry)"
						v-if="isWithConfirmation(entry)"
						:is-with-confirmation="true"
						:data="entry"
						@tap="tap"
					/>
				</div>
			</div>
			<template
				v-if="[5].includes(formNumber)"
			>
				<div class="line buttons">
					<ui-button color="red" v-hammer:tap="() => back()">{{ capFirst($t('back')) }}</ui-button>
					<ui-button color="red" v-hammer:tap="() => handleSubmit(submit)">{{ capFirst($t('start')) }}</ui-button>
				</div>
			</template>
			<template
				v-if="[1, 3, 4].includes(formNumber)"
			>
				<div class="line buttons">
					<ui-button type="login" color="back" v-hammer:tap="() => back()">{{ capFirst($t('back')) }}</ui-button>
					<ui-button type="login" color="confirm" :loading="app.loading && !termsLoading" v-hammer:tap="() => handleSubmit(submit)">{{ capFirst($t('next')) }}</ui-button>
				</div>
			</template>
			<template
				v-if="[2].includes(formNumber)"
			>
				<div class="buttons">
					<ui-button type="login" color="back" v-hammer:tap="() => back()">{{ capFirst($t('back')) }}</ui-button>
					<ui-button type="login" color="confirm" :loading="app.loading && !termsLoading" v-hammer:tap="() => handleSubmit(submit)">{{ capFirst($t('confirm')) }}</ui-button>
				</div>
			</template>
			<button type="submit"/>
		</form>
  </ValidationObserver>
</template>

<script>
	import { mixinForms } from '@/components/mixins/forms'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { _apollo } from '@/js/managers/_apollo'
	import { mapGetters } from 'vuex'
	import { removeAccessToken } from '@/js/jwt/jwt'
  import { removeStart, capFirst } from '@/js/utils/utils'
  import DialogBox from '@/components/game/dialog-box'
	import { isObject } from '@/js/utils/utils'

	export default {
		name: 'formsSignUp',
		mixins: [ mixinForms ],
    data() {
		  return {
		    fancybox: {
		      termsAndConditions: {
		        url: 'pdf/dummy.pdf'
          }
        },
		    termsAndConditionsText: null,
        termsLoading: false
      }
    },
		components: {
      DialogBox,
			ValidationObserver,
			ValidationProvider
		},
		computed: {
			...mapGetters([ 'app', 'game', 'user' ]),
		},
		methods: {
			capFirst,
			async back() {
				await removeAccessToken()
				await this.$store.dispatch('removeUser')
				await this.$router.push({ name: 'login' })
			},
			start() {
				this.$router.push({ name: 'home' })
			},
			async onError(error) {
				let { message } = error
				message = removeStart(message, ':')
				await this.$store.dispatch('add_notification', { status: 'error', message })
			},
			async submit() {
				const { submitDisabled, onError } = this
				if (submitDisabled) return
				this.submitDisabled = true
				const { progressionId, $refs: { signUp = null } = {} } = this
				if (!progressionId) {
					this.submitDisabled = false
					return
				}
				let mutation = 'SUBMIT_FORM'
				let answers = []
				const { $refs: { form = [] } = {}, $refs:refs } = this
				const inputs = Object.entries(refs)
					.filter(([key]) => key.startsWith('input-'))
					.map(x => x[1][0])
				for (let element of inputs) {
					const { formElementId, value } = element
					const data = value === null
						? [ '' ]
						: Array.isArray(value)
							? value.map(x => x.value)
							: isObject(value) && value.value
								? [ value.value ]
								: value === true || value === false
									? String(value)
									: [ value ]
					if (formElementId) {
						answers = [ ...answers, {
							formElementId,
							data
						}]
					}
				}
				let variables = { progressionId, answers }
				const { progression: { id } = {} } = await _apollo({ mutation, variables, onError })
				if (!id) {
					this.submitDisabled = false
					return
				}
				mutation = 'COMPLETE_STEP'
				variables = { progressionId: id }
				const result = await _apollo({ mutation, variables, onError })
				if (!result) {
					this.submitDisabled = false
					return
				}
				const { progression: { clientUser: { onboarding = null } = {} } = {}, gameCompleted, nextStep: { content:step } = {} } = result

				if (gameCompleted && onboarding === 'is_validated') {
					await this.$store.dispatch('get_current_user')
					await this.$router.push({ name: 'home' })
				} else {
					if (step) await this.$store.dispatch('setFormStep', { step })
					await this.$store.dispatch('get_current_user')
				}

				if (signUp) signUp.reset()
				this.submitDisabled = false
				this.formError = null
			},
      open_url(url) {
        if (url) {
          let win = window.open(url, '_blank')
          win.focus()
        }
        this.save()
      }
		}
	}
</script>

<style lang="stylus" scoped>
	@import '../../assets/css/forms-base'

	.form-content-text-themed
		color white
	.form-btn-themed
		background-color #61789E
		margin-bottom 20px
	.title
		text-align center
		font-size 1.1em
		font-weight bold
		color white
		padding-bottom 10px
		border-bottom 2px solid white
		margin-bottom 24px
	.text
		color white
	.text-smaller
		font-size 0.85em
	.GRP-1
		background-color white
		border-radius 5px
		padding 16px 16px 4px 16px
		box-shadow 0px 3px 6px alpha(#000, 30%)
		> div
			margin 0 0 12px 0
</style>
